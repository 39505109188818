@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: 'Muli';
  background-color: #F3CFC6;
}

main{}

.row{
  display: flex;
  align-items: center;
  justify-content: center;
}

h1{
  line-height: 1;
  font-weight: normal;
  font-size: 90px;
  color: black;
}

h2{
  line-height: 1.2;
  font-weight: normal;
  font-size: 30px;
}

a{
  color: white;
  background-color: red;
  padding: 5px 20px;
  padding-top: 2px;
  display: inline-block;
  text-decoration: none;
  margin-top: 15px;
}

.stacked{
  flex-direction: column;
}

.text{
  text-align: center;
}

@media (max-width:875px) {
  h1{
    font-size: 80px;
    margin-bottom: 30px;
  }
  h2{
    font-size: 25px;
    margin-bottom: 40px;
  }
  .row{
    flex-direction: column;
  }
}