.App {
}

.name{
  padding: 50px
}

.text{
  padding:30px;
}

@media (max-width:875px) {
  .name{
    padding: 0px 30px;
  }
  .text{
    padding: 0px 30px;
  }
}
